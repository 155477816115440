export const SET_OPEN_MODAL = 'SET_OPEN_MODAL';
export const SET_HYPOTHEQUES_MARITIMES = 'SET_HYPOTHEQUES_MARITIMES';
export const SET_SAISIES_NAVIRES = 'SET_SAISIES_NAVIRE';
export const SET_HYPOTHEQUES_RIF = 'SET_HYPOTHEQUES_RIF';

export const mutations = {
  [SET_OPEN_MODAL](state, modalOpen) {
    state.modalOpen = modalOpen;
  },
  [SET_HYPOTHEQUES_MARITIMES](state, hypothequesMaritimes) {
    state.hypothequesMaritimes = hypothequesMaritimes;
  },
  [SET_SAISIES_NAVIRES](state, saisiesNavires) {
    state.saisiesNavires = saisiesNavires;
  },
  [SET_HYPOTHEQUES_RIF](state, hypothequesRIF) {
    state.hypothequesRIF = hypothequesRIF;
  },
};
