import { createStore } from 'vuex';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state = {
  modalOpen: false,
  hypothequesMaritimes: [],
  saisiesNavires: [],
  hypothequesRIF: [],
};

export default createStore({
  state,
  getters,
  mutations,
  actions,
});
