<template>
  <div class="view export">
    <div class="content" v-if="loading">
      <Loader />
    </div>

    <div
      class="page"
      v-for="(hypothequeMaritime, index) in hypothequesMaritimes"
      :key="hypothequeMaritime.donneesGenerales.hypothequeUuid"
    >
      <CngNav />
      <div class="search-criteria-container content">
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.boat-id-short') }}</div>
          <div class="value" v-if="boatId">{{ $t('boatId') }} {{ boatId }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.siren') }}</div>
          <div class="value" v-if="siren">{{ formatSiren(siren) }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.company-name') }}</div>
          <div class="value" v-if="company">{{ company }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.first-name') }}</div>
          <div class="value" v-if="firstName">{{ firstName }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.last-name') }}</div>
          <div class="value" v-if="lastName">{{ lastName }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
      </div>

      <div class="result-content content" v-if="hypothequesMaritimes && hypothequesMaritimes.length > 0">
        <HypothequeCard :hypothequeMaritime="hypothequeMaritime" />

        <div class="disclaimer">{{ $t('disclaimer') }}</div>
      </div>

      <div class="result-content content" v-else>
        <h5>{{ $t('noInscription') }}</h5>

        <div class="disclaimer">{{ $t('disclaimer') }}</div>
      </div>

      <div class="spacer"></div>
      <div class="pagination content">{{ index + 1 }} / {{ pageCount }}</div>
    </div>

    <div class="page" v-for="(saisieNavire, index) in saisiesNavires" :key="saisieNavire.donneesGenerales.saisieUuid">
      <CngNav />
      <div class="search-criteria-container content">
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.boat-id-short') }}</div>
          <div class="value" v-if="boatId">{{ $t('boatId') }} {{ boatId }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.siren') }}</div>
          <div class="value" v-if="siren">{{ formatSiren(siren) }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.company-name') }}</div>
          <div class="value" v-if="company">{{ company }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.first-name') }}</div>
          <div class="value" v-if="firstName">{{ firstName }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.last-name') }}</div>
          <div class="value" v-if="lastName">{{ lastName }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
      </div>

      <div class="result-content content" v-if="saisiesNavires && saisiesNavires.length > 0">
        <SaisieCard :saisieNavire="saisieNavire" />

        <div class="disclaimer">{{ $t('disclaimer') }}</div>
      </div>

      <div class="result-content content" v-else>
        <h5>{{ $t('noInscription') }}</h5>

        <div class="disclaimer">{{ $t('disclaimer') }}</div>
      </div>

      <div class="spacer"></div>
      <div class="pagination content">{{ hypothequesMaritimes.length + index + 1 }} / {{ pageCount }}</div>
    </div>

    <div
      class="page"
      v-for="hypothequeMaritime in hypothequesRIF"
      :key="hypothequeMaritime.donneesGenerales.hypothequeUuid"
    >
      <CngNav />
      <div class="search-criteria-container content">
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.boat-id-short') }}</div>
          <div class="value" v-if="boatId">{{ $t('boatId') }} {{ boatId }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.siren') }}</div>
          <div class="value" v-if="siren">{{ formatSiren(siren) }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.company-name') }}</div>
          <div class="value" v-if="company">{{ company }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.first-name') }}</div>
          <div class="value" v-if="firstName">{{ firstName }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
        <div class="search-criteria">
          <div class="title">{{ $t('search-property.last-name') }}</div>
          <div class="value" v-if="lastName">{{ lastName }}</div>
          <div class="value" v-else>{{ $t('noInformation') }}</div>
        </div>
      </div>

      <div class="result-content content" v-if="hypothequesRIF && hypothequesRIF.length > 0">
        <RIFCard :hypothequeMaritime="hypothequeMaritime" />

        <div class="disclaimer">{{ $t('disclaimer') }}</div>
      </div>

      <div class="result-content content" v-else>
        <h5>{{ $t('noInscription') }}</h5>

        <div class="disclaimer">{{ $t('disclaimer') }}</div>
      </div>

      <div class="spacer"></div>
      <div class="pagination content">
        {{ hypothequesMaritimes.length + saisiesNavires.length + 1 }} / {{ pageCount }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatSiren } from '@/helpers/formatter.js';

import CngNav from '@/components/CNGNav.vue';
import HypothequeCard from '@/components/HypothequeCard.vue';
import RIFCard from '@/components/RIFCard.vue';
import SaisieCard from '@/components/SaisieCard.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'Export',
  components: {
    CngNav,
    Loader,
    HypothequeCard,
    RIFCard,
    SaisieCard,
  },
  watch: {
    $route() {
      if (this.$route.name === 'Export') this.performSearch();
    },
  },
  computed: {
    ...mapGetters({
      hypothequesMaritimes: 'hypothequesMaritimes',
      saisiesNavires: 'saisiesNavires',
      hypothequesRIF: 'hypothequesRIF',
    }),
    pageCount() {
      return this.hypothequesMaritimes.length + this.saisiesNavires.length + this.hypothequesRIF.length;
    },
  },
  data() {
    return {
      formatSiren,
      loading: false,

      boatId: null,
      siren: null,
      firstName: null,
      lastName: null,
      company: null,
      address: null,
    };
  },
  mounted() {
    this.performSearch();
    this.initQuery();

    window.onafterprint = this.afterPrint;
  },
  methods: {
    initQuery() {
      this.boatId = this.$route.query.numnavire;
      this.siren = this.$route.query.siren;
      this.firstName = this.$route.query.prenom;
      this.lastName = this.$route.query.nom;
      this.company = this.$route.query.denomination;
      this.address = this.$route.query.adresse;
    },
    async performSearch() {
      if (Object.keys(this.$route.query).length) {
        this.loading = true;
        await this.$store.dispatch('getHypothequesMaritimes', { query: this.$route.query });
        this.loading = false;

        setTimeout(() => {
          window.print();
        }, 100);
      }
    },
    afterPrint() {
      this.$router.push({ name: 'Search', query: this.$route.query });
    },
  },
};
</script>

<style scoped lang="scss">
.export {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background: #262631;

  .page {
    position: relative;

    width: 21cm;
    height: 29.7cm;

    display: flex;
    flex-direction: column;

    background: $white-color;
    padding: 0 0 32px;
    overflow: hidden;

    margin: 64px auto;
    border-radius: 4px;
    box-shadow: 4px 0 24px -4px rgba(0, 0, 0, 0.2);

    .spacer {
      flex-grow: 1;
      height: 100%;
    }
  }

  :deep(nav) {
    padding: 24px 0;
    background: $white-color;
    border-bottom: solid #e4e4e4 2px;
    margin-bottom: 32px;
    padding: 16px 0;

    .content {
      max-width: unset;
      padding: 0 1cm;
    }
  }

  h5 {
    font-size: 40px;
    font-weight: 700;
    color: $darkblue-color;
    margin: 64px 0 32px 0;
  }

  .content {
    max-width: unset;
    padding: 0 1cm;

    &.pagination {
      height: fit-content;
      text-align: center;
      font-size: 0.3cm;
      opacity: 0.8;
      margin-top: 8px;
    }
  }

  .search-criteria-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.8cm;
    color: $darkblue-color;

    .search-criteria {
      width: 30%;
      margin: 0 2% 16px 0;

      .title {
        margin-bottom: 4px;
        font-weight: 700;
      }
    }
  }

  .result-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .description {
    margin-bottom: 48px;
  }

  .disclaimer {
    padding: 24px 32px;
    background: rgba($focus-color, 0.15);
    border: solid 1px $focus-color;
    color: $darkblue-color;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
  }

  :deep(.hypotheque-card) {
    flex-shrink: 0;
    padding: 0.8cm 1.2cm 0.8cm;
    margin-bottom: 8px;
    border: solid #e4e4e4 2px;

    h1 {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 12px;
    }

    .title-container {
      flex-wrap: nowrap;
      align-items: flex-end;

      .title {
        display: block;

        .label {
          margin: 8px 0 0;
        }
      }
    }

    .id-container {
      flex-wrap: nowrap;

      .registrationId {
        margin-bottom: 0;
      }
    }

    .vertu-container {
      h2 {
        margin-bottom: 0;
      }
    }

    .container {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.6cm;

      &.small {
        margin-bottom: 16px;

        & > div {
          width: 30%;
          margin-right: 16px;
          margin-bottom: 0;
        }
      }

      .creancier-container {
        width: 50%;
        margin-right: 0;
        margin-bottom: 0;
      }

      .debiteur-container {
        width: 50%;
        margin-right: 0;
        margin-bottom: 0;
      }

      .info-container {
        width: 100%;
        margin: 0.6cm 0 0;

        .info-item {
          width: 50%;
          margin-bottom: 16px;

          &:nth-child(3n),
          &:nth-child(4n) {
            margin-bottom: 0;
          }
        }
      }
    }

    .complementary-container {
      display: grid;
      grid-template: 'line-1 line-1 button' 'line-2 line-2 button';

      .complimentary-info {
        margin-bottom: 8px;
      }
    }

    .document-button {
      display: none;
    }
  }

  :deep(.saisie-card) {
    flex-shrink: 0;
    padding: 0.8cm 1.2cm 0.8cm;
    margin-bottom: 8px;
    border: solid #e4e4e4 2px;

    h1 {
      width: unset;
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 12px;
    }

    .title-container {
      flex-wrap: nowrap;

      .title {
        flex-wrap: nowrap;

        .label {
          margin: 0 16px;
        }
      }
    }

    .container {
      flex-wrap: nowrap;
      margin-bottom: 48px;

      & > div {
        width: 30%;
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    .info-container {
      margin-bottom: 48px;

      .info-item {
        width: 15%;
        margin-right: 8px;
        margin-bottom: 0;

        &.big {
          width: 30%;
          margin-right: 16px;
        }
      }
    }

    .complementary-container {
      .complimentary-info {
        margin-bottom: 8px;
      }
    }

    .document-button {
      width: 320px;
    }
  }

  :deep(.rif-card) {
    flex-shrink: 0;
    padding: 0.8cm 1.2cm 0.8cm;
    margin-bottom: 8px;
    border: solid #e4e4e4 2px;

    .container {
      display: none;
    }

    .print-container {
      display: block;
    }
  }
}

@media print {
  * {
    color-adjust: exact !important;
    transition: none !important;
  }

  @page {
    size: A4 portrait;
    background: #f4f5f6;
    margin: 0;
  }

  .export {
    .page {
      margin: 0;
      border-radius: 0;
      box-shadow: none;
      page-break-after: always;
    }
  }
}
</style>
