<template>
  <div class="view home">
    <div class="head">
      <div class="content">
        <div class="title-container">
          <h1 class="title">{{ $t('title') }}</h1>
        </div>
      </div>
    </div>
    <SearchBar />
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'Home',
  components: {
    SearchBar,
  },
};
</script>

<style scoped lang="scss">
.home {
  .head {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 640px;
    max-height: 60vh;
    background-image: url('~@/assets/illustration.webp');
    background-size: cover;
    background-position: left bottom;

    &::before {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .title-container {
      position: relative;
      z-index: 100;
      max-width: 640px;
      margin-top: 96px;
      color: $white-color;

      h1 {
        font-family: $title-font;
        font-weight: 700;
        font-size: min(85px, 10vw);
      }

      p {
        margin-top: 32px;
        font-size: 18px;
        line-height: 28px;

        &.author {
          margin-top: 8px;
          font-size: 14px;
          font-style: italic;
        }
      }
    }
  }

  .disclamer {
    font-size: 10px;
    text-align: right;
    margin-top: -112px;
    margin-bottom: 112px;
    color: rgba($darkblue-color, 0.6);
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .home {
    .head {
      max-height: none;

      .title-container {
        margin-top: 128px;
      }
    }
  }
}
</style>
