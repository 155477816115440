<template>
  <div class="view search">
    <div class="content search-result-disclamer">{{ $t('searchResultDisclamer') }}</div>
    <SearchBarResult />

    <div class="content" v-if="loading">
      <Loader />
    </div>

    <div class="content" v-if="error">
      <div class="error">{{ $t('errorMessage') }}</div>
    </div>

    <div class="content" v-else-if="!loading && !isEmpty">
      <button class="download-button" @click="downloadFile">
        <DownloadIcon />
        <span>{{ $t('downloadFile') }}</span>
      </button>

      <HypothequeCard
        :hypothequeMaritime="hypothequeMaritime"
        v-for="hypothequeMaritime in hypothequesMaritimes"
        :key="hypothequeMaritime.donneesGenerales.hypothequeUuid"
      />
      <SaisieCard
        :saisieNavire="saisieNavire"
        v-for="saisieNavire in saisiesNavires"
        :key="saisieNavire.donneesGenerales.saisieUuid"
      />
      <RIFCard
        :hypothequeMaritime="hypothequeMaritime"
        v-for="hypothequeMaritime in hypothequesRIF"
        :key="hypothequeMaritime.donneesGenerales.hypothequeUuid"
      />

      <div class="disclaimer">{{ $t('disclaimer') }}</div>
    </div>

    <div class="content" v-else-if="!loading && !error">
      <button class="download-button" @click="downloadFile">
        <DownloadIcon />
        <span>{{ $t('downloadFile') }}</span>
      </button>

      <h5>{{ $t('noInscription') }}</h5>

      <div class="disclaimer">{{ $t('disclaimer') }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Loader from '@/components/Loader.vue';
import HypothequeCard from '@/components/HypothequeCard.vue';
import RIFCard from '@/components/RIFCard.vue';
import SaisieCard from '@/components/SaisieCard.vue';
import SearchBarResult from '@/components/SearchBarResult.vue';

import DownloadIcon from '@/assets/icons/Download.svg';

export default {
  name: 'Search',
  components: {
    Loader,
    DownloadIcon,
    HypothequeCard,
    RIFCard,
    SaisieCard,
    SearchBarResult,
  },
  watch: {
    $route() {
      this.performSearch();
    },
  },
  computed: {
    ...mapGetters({
      hypothequesMaritimes: 'hypothequesMaritimes',
      saisiesNavires: 'saisiesNavires',
      hypothequesRIF: 'hypothequesRIF',
    }),
    isEmpty() {
      return !(
        (this.hypothequesMaritimes && this.hypothequesMaritimes.length > 0) ||
        (this.saisiesNavires && this.saisiesNavires.length > 0) ||
        (this.hypothequesRIF && this.hypothequesRIF.length > 0)
      );
    },
  },
  data() {
    return {
      loading: false,
      error: false,
    };
  },
  mounted() {
    this.performSearch();
  },
  methods: {
    async performSearch() {
      if (Object.keys(this.$route.query).length) {
        this.loading = true;
        this.error = false;

        try {
          await this.$store.dispatch('getHypothequesMaritimes', { query: this.$route.query });
        } catch (e) {
          this.error = true;
        } finally {
          this.loading = false;
        }
      }
    },
    downloadFile() {
      this.$router.push({ name: 'Export', query: this.$route.query });
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  background: #f4f5f6;
  min-height: 70vh;
  padding: 0 0 32px;

  .loader {
    $size: 96px;
    width: $size;
    height: $size;
    display: grid;
    grid-template: 'a b' 'c d';
    margin: 10vh auto;

    .loader-item {
      margin: 2px;
      background: $darkblue-color;

      animation-name: load;
      animation-duration: 0.7s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;

      &.loader-1 {
        animation-delay: -0.4s;
      }
      &.loader-2 {
        animation-delay: -0.3s;
      }
      &.loader-3 {
        animation-delay: -0.2s;
      }
      &.loader-4 {
        animation-delay: -0.1s;
      }
    }
  }

  .error {
    margin: 12px 0 0;
    padding: 24px 32px;
    background: rgba($red-color, 0.15);
    border: solid 1px $red-color;
    color: $red-color;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
  }

  .search-result-disclamer {
    margin: 0 auto 8px;
    padding-top: 24px;
    font-size: 12px;
    font-style: italic;
    color: rgba($darkblue-color, 0.6);
  }

  .search-bar.content {
    padding-top: 0;
  }

  .download-button {
    position: relative;
    margin: 24px 0 8px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: transparent;
    color: $focus-color;
    overflow: hidden;

    padding: 8px 16px;
    border-radius: 4px;

    cursor: pointer;
    outline: solid 0 $focus-color;
    transition: 0.2s ease-out;

    svg {
      flex-shrink: 0;
      width: 16px;
      margin-right: 12px;
    }

    span {
      font-weight: 700;
      white-space: nowrap;
    }

    &:hover {
      background: rgba($focus-color, 0.15);
    }

    &:active {
      color: rgba($focus-color, 0.6);
    }

    &:focus-visible {
      outline: solid 2px $focus-color;
    }
  }

  h5 {
    font-size: 40px;
    font-weight: 700;
    color: $darkblue-color;
    margin: 64px 0 48px 0;
  }

  .search-disclamer {
    margin: 16px 0 8px;
    font-size: 12px;
    font-style: italic;
    color: rgba($darkblue-color, 0.6);
  }

  .disclaimer {
    padding: 24px 32px;
    margin: 12px 0 0;
    background: rgba($focus-color, 0.15);
    border: solid 1px $focus-color;
    color: $darkblue-color;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .search {
    h5 {
      margin: 15vh 0 64px 0;
    }
  }
}
</style>
