<template>
  <div class="search-bar content">
    <div class="selector">
      <div
        class="option option-1"
        :class="{ active: searchType === 'boat' }"
        @click="searchType = 'boat'"
        @keydown.enter="searchType = 'boat'"
        tabindex="0"
      >
        {{ $t('navire') }}
      </div>
      <div
        class="option option-2"
        :class="{ active: searchType === 'owner' }"
        @click="searchType = 'owner'"
        @keydown.enter="searchType = 'owner'"
        tabindex="0"
      >
        {{ $t('proprietaire') }}
      </div>
    </div>
    <div class="search-block">
      <div class="search-result-disclamer">{{ $t('searchResultDisclamer') }}</div>
      <div class="boat-search search-container" v-if="searchType === 'boat'">
        <input
          type="text"
          class="boat-id"
          v-model="boatId"
          :placeholder="`${$t('search-property.boat-id')}*`"
          @keydown.enter="search"
          autofocus="true"
        />
        <button class="search-button" :class="{ loading: loading }" @click="search">
          <SearchIcon v-if="!loading" />
          <span v-if="loading">{{ $t('loading') }}</span>
          <span v-else>{{ $t('search') }}</span>
        </button>
      </div>

      <div class="boat-id-information" v-if="searchType === 'boat'">*{{ $t('search-property.boatIdInformation') }}</div>

      <div class="owner-search search-container" v-if="searchType === 'owner'">
        <input
          type="text"
          id="siren"
          class="siren"
          v-model="siren"
          :placeholder="$t('search-property.siren')"
          @keydown.enter="search"
        />
        <input
          type="text"
          id="company-name"
          class="company-name"
          v-model="company"
          :placeholder="$t('search-property.company-name')"
          @keydown.enter="search"
        />
        <input
          type="text"
          id="last-name"
          class="last-name"
          v-model="lastName"
          :placeholder="$t('search-property.last-name')"
          @keydown.enter="search"
        />
        <button class="search-button" :class="{ loading: loading }" @click="search">
          <SearchIcon v-if="!loading" />
          <span v-if="loading">{{ $t('loading') }}</span>
          <span v-else>{{ $t('search') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/assets/icons/Search.svg';

export default {
  name: 'SearchBar',
  components: {
    SearchIcon,
  },
  data() {
    return {
      searchType: 'boat',
      loading: false,

      boatId: null,
      siren: null,
      lastName: null,
      company: null,
    };
  },
  methods: {
    search() {
      if (this.loading) return;

      const boatQuery = {};
      if (this.boatId) boatQuery.numnavire = this.boatId;

      if (this.searchType === 'boat' && Object.keys(boatQuery).length) {
        this.$router.push({ name: 'Search', query: boatQuery });
        return;
      }

      const ownerQuery = {};
      if (this.siren) ownerQuery.siren = this.siren;
      if (this.lastName) ownerQuery.nom = this.lastName;
      if (this.company) ownerQuery.denomination = this.company;

      if (this.searchType === 'owner' && Object.keys(ownerQuery).length) {
        this.$router.push({ name: 'Search', query: ownerQuery });
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  position: relative;
  z-index: 100;
  width: 100%;

  margin-top: -112px !important;
  margin-bottom: 128px !important;

  .selector {
    display: flex;
    margin: 0 0 0 24px;
    color: $white-color;

    .option {
      padding: 16px 32px;
      background: $darkblue-color;
      width: fit-content;
      font-weight: 700;

      outline: solid 0 $focus-color;
      outline-offset: 2px;
      transition: background 0.2s ease-out;

      &:focus-visible {
        z-index: 10;
        outline: solid 1px $focus-color;
      }

      &:not(.active) {
        font-weight: 500;
        background: mix($darkblue-color, $black-color, 30);
        cursor: pointer;

        &:hover {
          background: mix($darkblue-color, $black-color, 70);
        }
      }
    }
  }

  .search-block {
    padding: 16px 24px 24px;
    background: $darkblue-color;
    box-shadow: 0 16px 24px -4px rgba(0, 0, 0, 0.2), 0 4px 8px -4px rgba($darkblue-color, 0.4);

    .search-result-disclamer {
      margin: 0 0 12px;
      font-size: 12px;
      font-style: italic;
      color: $white-color;
    }

    .search-container {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(6, 1fr);

      &.boat-search {
        input.boat-id {
          grid-column: 1 / 7;
        }
        .search-button {
          grid-column: 1 / 7;
        }
      }

      &.owner-search {
        input.siren {
          grid-column: 1 / 7;
        }
        input.company-name {
          grid-column: 1 / 7;
        }
        input.last-name {
          grid-column: 1 / 7;
        }
        .search-button {
          grid-column: 1 / 7;
        }
      }
    }

    .boat-id-information {
      color: rgba($white-color, 0.6);
      margin: 12px 0 0 0;
      font-size: 12px;
    }

    input {
      width: 100%;
      height: 64px;
      padding: 8px 16px;
      border: solid 2px $white-color;
      background: $white-color;
      font-size: 16px;

      outline: solid 0 $focus-color;
      transition: 0.2s ease-out;

      &.error {
        border-color: $accent-color;
        animation: wiggle 0.4s;

        &::placeholder {
          color: $accent-color;
          opacity: 1;
        }
      }

      &:focus-visible {
        border-color: $focus-color;
      }

      &::placeholder {
        color: $darkblue-color;
        opacity: 0.3;
        font-family: $text-font;
        transition: 0.1s;
      }
    }

    .search-button {
      position: relative;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      background: $accent-color;
      border: solid 2px transparent;
      color: $white-color;
      overflow: hidden;

      cursor: pointer;
      outline: solid 0 $focus-color;
      outline-offset: 1px;
      transition: 0.2s ease-out;

      svg {
        max-height: 24px;
        max-width: 24px;
        margin-right: 16px;
      }

      span {
        font-weight: 700;
      }

      &:focus-visible {
        outline: solid 2px $focus-color;
      }

      &::after {
        position: absolute;
        content: '';
        top: 0;
        left: -32px;
        height: 100%;
        width: calc(100% + 64px);
        border-radius: 8px;
        background-color: rgba($white-color, 5%);

        transform: scaleX(0);
        transform-origin: 100% 100%;
        transition: transform 0.8s cubic-bezier(0.53, 0.21, 0, 1);
        will-change: transform;
      }

      &.loading {
        cursor: progress;

        &::after {
          transform-origin: 0 0;
          transform: scaleX(1);
          border-radius: 0;

          animation-name: grow;
          animation-duration: 1.6s;
          animation-iteration-count: infinite;
        }
      }

      &:not(.loading):hover {
        background: darken($accent-color, 5);
      }

      &:not(.loading):active {
        background: darken($accent-color, 8);
        transform: scale(0.975);
      }
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .search-bar {
    .search-block {
      padding: 32px;

      .search-container {
        grid-gap: 24px;
        grid-template-columns: repeat(12, 1fr);

        &.boat-search {
          input.boat-id {
            grid-column: 1 / 10;
          }
          .search-button {
            grid-column: 10 / 13;
          }
        }

        &.owner-search {
          input.siren {
            grid-column: 1 / 5;
          }
          input.company-name {
            grid-column: 5 / 13;
          }
          input.last-name {
            grid-column: 1 / 9;
          }
          .search-button {
            grid-column: 9 / 13;
          }
        }
      }
    }
  }
}
</style>
