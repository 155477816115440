<template>
  <footer>
    <div class="top-container">
      <div class="content">
        <div class="social-container">
          <div class="social-title">{{ $t('follow-us') }}</div>
          <div class="social-button-container">
            <a class="social-button" aria-label="Twitter" href="https://twitter.com/CNG_TC" target="_blank">
              <TwitterIcon />
            </a>
            <a class="social-button" aria-label="Linkedin" href="https://www.linkedin.com/company/cngtc/" target="_blank">
              <LinkedinIcon />
            </a>
            <a class="social-button" aria-label="Facebook" href="https://www.facebook.com/CNGTC/" target="_blank">
              <FacebookIcon />
            </a>
            <a class="social-button" aria-label="YouTube" :href="youtubeLink" target="_blank">
              <YoutubeIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="copyright">© {{ year }} {{ $t('copyright') }}</div>
      <div class="link-container">
        <router-link to="/mentions-legales" class="link">
          <span>Mentions légales</span>
        </router-link>
        <div class="separator">-</div>
        <router-link to="/charte-gestion-des-cookies" class="link">
          <span>Charte des cookies</span>
        </router-link>
        <div class="separator">-</div>
        <router-link to="/charte-rgpd" class="link">
          <span>Charte RGPD</span>
        </router-link>
        <div class="separator">-</div>
        <a href="https://www.cngtc.fr/" class="link">
          <span>CNGTC</span>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import TwitterIcon from '@/assets/icons/social/Twitter.svg';
import LinkedinIcon from '@/assets/icons/social/Linkedin.svg';
import FacebookIcon from '@/assets/icons/social/Facebook.svg';
import YoutubeIcon from '@/assets/icons/social/Youtube.svg';

export default {
  name: 'CNG Footer',
  components: {
    TwitterIcon,
    LinkedinIcon,
    FacebookIcon,
    YoutubeIcon,
  },
  data() {
    return {
      year: new Date().getFullYear(),

      // Social
      youtubeLink: "https://www.youtube.com/channel/UChapBLU3lVVfq7YzsAL_QdA",
    };
  },
};
</script>

<style scoped lang="scss">
footer {
  width: 100%;

  .top-container {
    width: 100%;
    min-height: 128px;
    background: $darkblue-color;

    .social-container {
      padding: 48px 0;

      .social-title {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: bold;
        color: $white-color;
        text-align: center;
      }

      .social-button-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .social-button {
          $size: 48px;
          width: $size;
          height: $size;
          border-radius: $size;
          background: $white-color;
          margin-right: 16px;

          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;
          outline: solid 0 $focus-color;
          transition: 0.2s ease-out;

          svg {
            max-width: 28px;
            max-height: 28px;
            transition: 0.2s ease-out;
          }

          &:hover {
            svg {
              opacity: 0.8;
              transform: scale(1.1);
            }
          }

          &:focus-visible {
            outline: solid 2px $focus-color;
          }

          &:active {
            svg {
              opacity: 0.7;
              transform: scale(0.95);
            }
          }
        }
      }
    }
  }

  .bottom-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 24px 0;
    background: $accent-color;
    color: $white-color;
    font-size: 16px;

    .copyright {
      text-align: center;
      font-weight: 400;
      line-height: 24px;
      padding: 0 24px;
      margin-bottom: 24px;
    }

    .link-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 12px;
      line-height: 24px;
      font-weight: bold;

      .link {
        width: 100%;
        margin: 8px 0;
        text-align: center;
        text-decoration: underline;
        outline: solid 0 $focus-color;
        transition: 0.2s ease-in;

        &:hover {
          opacity: 0.8;
        }

        &:focus-visible {
          border-bottom: solid 2px $focus-color;
        }
      }

      .separator {
        display: none;
        margin: 0 6px;
      }
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  footer {
    .bottom-container {
      .copyright {
        padding: 0;
        margin-bottom: 0;
      }

      .link-container {
        flex-wrap: nowrap;

        .link {
          width: auto;
          margin: 8px 0;
        }

        .separator {
          display: block;
        }
      }
    }
  }
}
</style>
