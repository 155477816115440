<template>
  <div class="saisie-card">
    <div class="title-container">
      <div class="title">
        <div class="title-content">
          <h4>{{ $t('boatName') }}</h4>
          <h1>{{ saisieNavire.infoSaisie.nomNavire }}</h1>
        </div>
        <div class="label">{{ $t('seizure') }}</div>
      </div>
    </div>

    <div class="date">{{ saisieNavire.infoSaisie.dateActeSaisie }}</div>

    <div class="container">
      <div class="creancier-container">
        <h2>{{ $t('creditor') }}</h2>
        <div class="info">
          <div class="name">
            <span class="lastname">{{ saisieNavire.infoSaisie.nomCreancier }}</span>
            <span class="firstname">{{ saisieNavire.infoSaisie.prenomCreancier }}</span>
          </div>
          <div class="address">{{ saisieNavire.infoSaisie.adresseCreancier }}</div>
        </div>
      </div>
      <div class="debiteur-container" v-if="saisieNavire.proprietaires">
        <h2>{{ $tc('owners', saisieNavire.proprietaires.length) }}</h2>
        <template v-for="proprietaire in saisieNavire.proprietaires" :key="proprietaire.idProprietaire">
          <div class="info" v-if="proprietaire.isProprietairemorale">
            <div class="name">
              <span class="company">{{ proprietaire.denominationProprietaireNavire }}</span>
            </div>
            <div class="address">{{ proprietaire.adresseProprietaireNavire }}</div>
            <div class="siren">{{ formatSiren(proprietaire.sirenProprietaireNavire) }}</div>
          </div>
          <div class="info" v-else>
            <div class="name">
              <span class="lastname">{{ proprietaire.nomProprietaireNavire }}</span>
              <span class="firstname">{{ proprietaire.prenomProprietaireNavire }}</span>
            </div>
            <div class="address">{{ proprietaire.adresseProprietaireNavire }}</div>
          </div>
        </template>
      </div>
      <div class="boat-container">
        <h2>{{ $t('boatInfo') }}</h2>
        <div class="info">
          <div class="name">{{ saisieNavire.infoSaisie.nomNavire }}</div>
          <div class="type">{{ $t('type', { type: saisieNavire.infoSaisie.typeNavire }) }}</div>
          <div class="gauge">{{ $t('gauge', { gauge: saisieNavire.infoSaisie.jaugeNavire }) }}</div>
          <div class="port">{{ $t('port', { port: saisieNavire.infoSaisie.portAttacheNavire }) }}</div>
          <div class="nationality">
            {{ $t('nationality', { nationality: saisieNavire.infoSaisie.nationaliteNavire }) }}
          </div>
        </div>
      </div>
    </div>

    <div class="info-container">
      <div class="info-item big">
        <h2>{{ $t('guardian') }}</h2>
        <div class="info">{{ saisieNavire.infoSaisie.nomGardien }} {{ saisieNavire.infoSaisie.prenomGardien }}</div>
      </div>
      <div class="info-item big">
        <h2>{{ $t('sum') }}</h2>
        <div class="info">{{ formatPriceString(saisieNavire.infoSaisie.sommePrincipal) }}</div>
      </div>
      <div class="info-item">
        <h2>{{ $t('rates') }}</h2>
        <div class="info">{{ saisieNavire.infoSaisie.interetPoursuivi }}</div>
      </div>
      <div class="info-item">
        <h2>{{ $t('fees') }}</h2>
        <div class="info">{{ formatPriceString(saisieNavire.infoSaisie.fraisPoursuivi) }}</div>
      </div>
    </div>

    <div class="complementary-container">
      <div class="complimentary-info">
        {{ $t('portLeave') }}
      </div>
      <div class="complimentary-info">
        {{ $t('seizureContest') }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatSiren, formatPriceString } from '@/helpers/formatter.js';

export default {
  name: 'SaisieCard',
  props: {
    saisieNavire: Object,
  },
  data() {
    return {
      formatSiren,
      formatPriceString,
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.saisie-card {
  position: relative;
  break-inside: avoid;
  page-break-before: always;

  width: 100%;
  color: $darkblue-color;
  background: $white-color;
  padding: 32px 48px;
  margin-bottom: 32px;

  overflow: hidden;

  h1 {
    width: 100%;
    margin-bottom: 16px;
    color: $darkblue-color;
    font-family: $title-font;
    font-size: 18px;
    font-weight: 700;
  }

  h2 {
    font-family: $title-font;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 16px;
  }

  .title-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title {
      display: flex;
      flex-wrap: wrap-reverse;
      align-items: center;

      .title-content {
        h4 {
          font-size: 12px;
          margin-bottom: 4px;
          margin-top: -16px;
          text-transform: none;
        }
      }

      .label {
        margin: 0 0 16px;
        padding: 8px 12px;
        border-radius: 4px;
        font-family: $title-font;
        font-size: 12px;
        font-weight: 700;

        color: $accent-color;
        background: rgba($accent-color, 0.15);
      }
    }
  }

  .date {
    margin-bottom: 32px;
    font-size: 14px;
    font-weight: 700;
  }

  .info {
    font-size: 14px;
    margin-bottom: 12px;

    & > div:not(.name) {
      line-height: 18px;
    }

    .name {
      font-weight: 700;
      margin-bottom: 6px;

      span:not(:last-child) {
        margin-right: 4px;
      }
    }

    .address {
      margin-bottom: 8px;
    }

    .gauge {
      margin-bottom: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  .info-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    width: 100%;

    .info-item {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  .complementary-container {
    .complimentary-info {
      font-size: 14px;
      margin-bottom: 24px;
      padding: 0 24px 0 0;

      &:first-child {
        grid-area: line-1;
      }
      &:nth-child(2) {
        grid-area: line-2;
      }

      .value {
        font-weight: 700;
        color: $focus-color;
      }
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .saisie-card {
    h1 {
      width: unset;
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 12px;
    }

    .title-container {
      flex-wrap: nowrap;

      .title {
        flex-wrap: nowrap;

        .label {
          margin: 0 16px;
        }
      }
    }

    .container {
      flex-wrap: nowrap;
      margin-bottom: 48px;

      & > div {
        width: 30%;
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    .info-container {
      margin-bottom: 48px;

      .info-item {
        width: 15%;
        margin-right: 8px;
        margin-bottom: 0;

        &.big {
          width: 30%;
          margin-right: 16px;
        }
      }
    }

    .complementary-container {
      .complimentary-info {
        margin-bottom: 8px;
      }
    }

    .document-button {
      width: 320px;
    }
  }
}
</style>
