<template>
  <div class="rif-card">
    <transition name="modal">
      <RifModal :close="closeModal" v-if="rifModalOpen" />
    </transition>

    <div class="title-container">
      <div class="title">
        <div class="title-content">
          <h4>{{ $t('boatName') }}</h4>
          <h1>{{ hypothequeMaritime.infoHypotheque.nomNavire }}</h1>
        </div>

        <div class="label">{{ $t('rif') }}</div>
      </div>
    </div>

    <div class="container">
      <div class="description">{{ $t('rifDescription') }}</div>
      <button class="document-button" @click="openModal">{{ $t('moreInfo') }}</button>
    </div>

    <div class="print-container">
      <div class="description">{{ $t('rifPrintDescription') }}</div>
    </div>
  </div>
</template>

<script>
import RifModal from '@/components/RifModal.vue';

export default {
  name: 'RIFCard',
  components: { RifModal },
  props: {
    hypothequeMaritime: Object,
  },
  data() {
    return {
      rifModalOpen: false,
    };
  },
  methods: {
    openModal() {
      this.$store.dispatch('openModal', { open: true });
      this.rifModalOpen = true;
      document.body.style.overflow = 'hidden';
    },
    closeModal() {
      this.$store.dispatch('openModal', { open: false });
      this.rifModalOpen = false;
      document.body.style.overflow = 'auto';
    },
  },
};
</script>

<style scoped lang="scss">
.rif-card {
  position: relative;
  break-inside: avoid;
  page-break-before: always;

  width: 100%;
  color: $darkblue-color;
  background: $white-color;
  padding: 32px 48px;
  margin-bottom: 32px;

  overflow: hidden;

  h1 {
    margin-bottom: 16px;
    color: $darkblue-color;
    font-family: $title-font;
    font-size: 18px;
    font-weight: 700;
  }

  .title-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title {
      display: flex;
      flex-wrap: wrap-reverse;
      align-items: center;

      .title-content {
        h4 {
          font-size: 12px;
          margin-bottom: 4px;
          margin-top: -16px;
          text-transform: none;
        }
      }

      .label {
        margin: 0 0 16px;
        padding: 8px 12px;
        border-radius: 4px;
        font-family: $title-font;
        font-size: 12px;
        font-weight: 700;

        color: #29438e;
        background: rgba(#29438e, 0.15);
      }
    }
  }

  .container {
    .description {
      padding-right: 32px;
      margin-bottom: 24px;
    }
  }

  .print-container {
    display: none;
  }

  .document-button {
    grid-area: button;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    margin-left: auto;
    background: $darkblue-color;
    border: solid 2px transparent;
    font-size: 16px;
    color: $white-color;
    flex-shrink: 0;
    overflow: hidden;

    cursor: pointer;
    outline: solid 0 $focus-color;
    outline-offset: 1px;
    transition: 0.2s ease-out;

    svg {
      max-height: 24px;
      max-width: 24px;
      margin-right: 16px;
    }

    span {
      font-weight: 700;
    }

    &:focus-visible {
      outline: solid 2px $focus-color;
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: -32px;
      height: 100%;
      width: calc(100% + 64px);
      border-radius: 8px;
      background-color: rgba($white-color, 5%);

      transform: scaleX(0);
      transform-origin: 100% 100%;
      transition: transform 0.8s cubic-bezier(0.53, 0.21, 0, 1);
      will-change: transform;
    }

    &.loading {
      cursor: progress;

      &::after {
        transform-origin: 0 0;
        transform: scaleX(1);
        border-radius: 0;

        animation-name: grow;
        animation-duration: 1.6s;
        animation-iteration-count: infinite;
      }
    }

    &:not(.loading):hover {
      background: lighten($darkblue-color, 8);
    }

    &:not(.loading):active {
      background: lighten($darkblue-color, 12);
      transform: scale(0.975);
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .rif-card {
    h1 {
      margin-bottom: 0;
    }

    .title-container {
      flex-wrap: nowrap;

      .title {
        flex-wrap: nowrap;

        .label {
          margin: 0 16px;
        }
      }
    }

    .container {
      display: flex;
      align-items: flex-end;

      .description {
        margin-bottom: 0;
      }
    }

    .document-button {
      width: 320px;
    }
  }
}
</style>
