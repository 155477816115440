<template>
  <div class="search-bar content" @keypress.enter="search">
    <input type="text" class="boat-id" v-model="boatId" :placeholder="$t('search-property.boat-id')" />
    <input type="text" id="siren" class="siren" v-model="siren" :placeholder="$t('search-property.siren')" />
    <input
      type="text"
      id="last-name"
      class="last-name"
      v-model="lastName"
      :placeholder="$t('search-property.last-name')"
    />
    <input
      type="text"
      id="company-name"
      class="company-name"
      v-model="company"
      :placeholder="$t('search-property.company-name')"
    />
    <button class="search-button" :class="{ loading: loading }" @click="search">
      <SearchIcon v-if="!loading" />
      <span v-if="loading">{{ $t('loading') }}</span>
      <span v-else>{{ $t('search') }}</span>
    </button>
  </div>
</template>

<script>
import SearchIcon from '@/assets/icons/Search.svg';

export default {
  name: 'SearchBarResult',
  components: {
    SearchIcon,
  },
  data() {
    return {
      loading: false,

      boatId: null,
      siren: null,
      lastName: null,
      company: null,
    };
  },
  watch: {
    $route() {
      this.initQuery();
    },
  },
  mounted() {
    this.initQuery();
  },
  methods: {
    initQuery() {
      this.boatId = this.$route.query.numnavire;
      this.siren = this.$route.query.siren;
      this.lastName = this.$route.query.nom;
      this.company = this.$route.query.denomination;
    },
    search() {
      if (this.loading) return;

      const query = {};
      if (this.boatId) query.numnavire = this.boatId;
      if (this.siren) query.siren = this.siren;
      if (this.lastName) query.nom = this.lastName;
      if (this.company) query.denomination = this.company;

      if (Object.keys(query).length) {
        this.$router.push({ name: 'Search', query: query });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  position: relative;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  input {
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    margin: 8px 0;
    border: solid 2px $white-color;
    background: $white-color;
    font-size: 16px;

    outline: solid 0 $focus-color;
    transition: 0.2s ease-out;

    &.error {
      border-color: $accent-color;
      animation: wiggle 0.4s;

      &::placeholder {
        color: $accent-color;
        opacity: 1;
      }
    }

    &:not(:placeholder-shown) {
      padding: 9px 17px;
      border-width: 1px;
      border-color: $darkblue-color;
    }

    &:focus-visible {
      padding: 8px 16px;
      border-width: 2px;
      border-color: $focus-color;
    }

    &::placeholder {
      color: $darkblue-color;
      opacity: 0.3;
      font-family: $text-font;
    }
  }

  .search-button {
    position: relative;
    height: 48px;
    width: 100%;
    margin-top: 8px;
    padding: 0 12px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: $accent-color;
    border: solid 2px transparent;
    color: $white-color;
    overflow: hidden;

    cursor: pointer;
    outline: solid 0 $focus-color;
    outline-offset: 1px;
    transition: 0.2s ease-out;

    svg {
      max-height: 24px;
      width: 24px;
      margin-right: 16px;
    }

    span {
      font-weight: 700;
    }

    &:focus-visible {
      outline: solid 2px $focus-color;
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: -32px;
      height: 100%;
      width: calc(100% + 64px);
      border-radius: 8px;
      background-color: rgba($white-color, 5%);

      transform: scaleX(0);
      transform-origin: 100% 100%;
      transition: transform 0.8s cubic-bezier(0.53, 0.21, 0, 1);
      will-change: transform;
    }

    &.loading {
      cursor: progress;

      &::after {
        transform-origin: 0 0;
        transform: scaleX(1);
        border-radius: 0;

        animation-name: grow;
        animation-duration: 1.6s;
        animation-iteration-count: infinite;
      }
    }

    &:not(.loading):hover {
      background: darken($accent-color, 5);
    }

    &:not(.loading):active {
      background: darken($accent-color, 8);
      transform: scale(0.975);
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .search-bar {
    flex-wrap: nowrap;
    justify-content: unset;

    &.content {
      padding: 32px 0 0 0;
    }

    input {
      margin: 8px 16px 8px 0;

      &.last-name {
        width: calc(50% - 8px);
      }
    }

    .search-button {
      width: auto;
      margin-top: 0;
    }
  }
}
</style>
