<template>
  <cng-nav v-if="$route.meta.nav" />
  <router-view />
  <cng-footer v-if="$route.meta.footer" />
</template>

<script>
import CngNav from '@/components/CNGNav.vue';
import CngFooter from '@/components/CNGFooter.vue';

export default {
  name: 'Home',
  components: {
    CngNav,
    CngFooter,
  },
};
</script>

<style lang="scss">
@import '@/styles/app.scss';
</style>
