<template>
  <div class="loader">
    <div class="loader-item loader-1"></div>
    <div class="loader-item loader-2"></div>
    <div class="loader-item loader-4"></div>
    <div class="loader-item loader-3"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style scoped lang="scss">
.loader {
  $size: 96px;
  width: $size;
  height: $size;
  display: grid;
  grid-template: 'a b' 'c d';
  margin: 10vh auto;

  .loader-item {
    margin: 2px;
    background: $darkblue-color;

    animation-name: load;
    animation-duration: 0.7s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    &.loader-1 {
      animation-delay: -0.4s;
    }
    &.loader-2 {
      animation-delay: -0.3s;
    }
    &.loader-3 {
      animation-delay: -0.2s;
    }
    &.loader-4 {
      animation-delay: -0.1s;
    }
  }
}
</style>
