import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import VueGtag from 'vue-gtag';
import { axiosHelper } from './helpers/axios';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(axiosHelper, { apiUrl: process.env.VUE_APP_API_URL, store });
app.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GTAG, enabled: false },
  },
  router
);

app.mount('#app');
