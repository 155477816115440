<template>
  <div class="modal-container">
    <div class="overlay"></div>
    <div class="modal content" v-if="hypothequeMaritime">
      <h1 class="title">{{ $t('modal.title') }}</h1>
      <div class="text">
        {{ $t('modal.line-1') }}
        <span class="name">{{ hypothequeMaritime.infoHypotheque.nomNavire }}</span>
        <br />
        {{ $t('modal.line-2') }}
      </div>
      <div class="greffe-container">
        <div class="line greffe">
          <PinIcon /> <span>{{ $t('modal.greffe', { greffe: hypothequeMaritime.infoHypotheque.greffe.nom }) }}</span>
        </div>
        <div class="line">
          <span>{{ hypothequeMaritime.infoHypotheque.greffe.adresse.rue }}</span>
        </div>
        <div class="line">
          <span>
            {{ hypothequeMaritime.infoHypotheque.greffe.adresse.codePostal }}
            {{ hypothequeMaritime.infoHypotheque.greffe.adresse.cedex }}
          </span>
        </div>
        <div class="line phone">
          <span>{{ formatPhone(hypothequeMaritime.infoHypotheque.greffe.telephone) }} </span>
        </div>
        <div class="line schedule">
          <span>{{ hypothequeMaritime.infoHypotheque.greffe.horaires }} </span>
        </div>
      </div>
      <button @click="close">{{ $t('modal.close') }}</button>
    </div>
  </div>
</template>

<script>
import { formatPhone } from '@/helpers/formatter.js';

import PinIcon from '@/assets/icons/Pin.svg';

export default {
  name: 'CertifiedModal',
  components: {
    PinIcon,
  },
  props: {
    hypothequeMaritime: Object,
    close: Function,
  },
  data() {
    return {
      formatPhone,
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped lang="scss">
.modal-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black-color, 0.4);
    opacity: 1;
  }

  .modal {
    position: relative;
    z-index: 10;
    height: fit-content;
    padding: 48px 32px;
    background: $white-color;
    box-shadow: 0 16px 24px -4px rgba(0, 0, 0, 0.2), 0 4px 8px -4px rgba($darkblue-color, 0.4);

    h1 {
      margin-bottom: 64px;
      color: $darkblue-color;
      font-family: $title-font;
      text-align: center;
      font-size: 48px;
      font-weight: bold;
    }

    .text {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;

      .name {
        font-weight: bold;
      }
    }

    .greffe-container {
      padding: 24px 32px;
      margin: 32px 0 0;
      background: rgba($focus-color, 0.15);
      border: solid 1px $focus-color;
      color: $darkblue-color;
      font-size: 14px;
      text-align: left;

      .line {
        display: flex;
        align-items: center;
        padding-left: 24px;
        margin-bottom: 4px;
        margin-left: 16px;

        &.greffe {
          font-weight: bold;
          margin-bottom: 16px;
          padding-left: 0;
        }

        &.phone {
          margin-top: 12px;
        }

        &.schedule {
          margin-top: 8px;
          font-size: 12px;
          opacity: 0.6;
        }
      }

      svg {
        width: 16px;
        margin-right: 8px;
      }
    }

    button {
      height: 48px;
      width: 100%;
      max-width: 256px;
      margin: 48px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-shrink: 0;
      margin-left: auto;
      background: $darkblue-color;
      border: solid 2px transparent;
      font-size: 16px;
      color: $white-color;
      overflow: hidden;

      cursor: pointer;
      outline: solid 0 $focus-color;
      outline-offset: 1px;
      transition: 0.2s ease-out;

      &:focus-visible {
        outline: solid 2px $focus-color;
      }

      &:hover {
        background: lighten($darkblue-color, 8);
      }

      &:active {
        background: lighten($darkblue-color, 12);
        transform: scale(0.975);
      }
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .modal-container {
    .modal {
      padding: 64px 96px;

      .greffe-container {
        .line {
          margin-left: calc(50% - 200px);
        }
      }
    }
  }
}
</style>
