<template>
  <nav id="nav">
    <div class="content">
      <router-link to="/" class="logo">
        <Logo class="head" v-if="$route.name === 'Home'" />
        <div class="text-container">
          <div class="line line-1">{{ $t('title-logo.line-1') }}</div>
          <div class="line line-2">{{ $t('title-logo.line-2') }}</div>
          <div class="line line-3 no-print">{{ $t('title-logo.line-3') }}</div>
          <div class="line line-4 no-print">{{ $t('title-logo.line-4') }}</div>
        </div>
      </router-link>
    </div>
  </nav>
</template>

<script>
import Logo from '@/assets/logo.svg';

export default {
  name: 'CNG Nav',
  components: {
    Logo,
  },
};
</script>

<style scoped lang="scss">
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 24px 32px;

  .logo {
    position: relative;
    width: fit-content;
    height: 48px;
    overflow: visible;

    display: flex;
    align-items: center;

    box-shadow: 0 0 0 0 $white-color, 0 0 0 0 $focus-color;
    border-radius: 4px;
    transition: 0.2s ease-out;

    .head {
      $radius: 48px;
      height: $radius;
      width: $radius;
      flex-shrink: 0;
      margin-right: 8px;
    }

    .text-container {
      font-family: $title-font;
      font-weight: 700;
      font-size: 12px;
      color: $blue-color;

      transition: 0.6s;

      .line {
        margin-bottom: 2px;
        text-transform: uppercase;
        letter-spacing: 1.25px;

        &.line-3,
        &.line-4 {
          font-size: 8px;
          margin-top: 4px;
          margin-bottom: 0;

          color: $red-color;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 24px;
      height: 200%;
      top: -50%;
      bottom: -50%;
      background: $white-color;
      transform: rotate(-30deg);
      opacity: 0.6;

      left: -25%;
      transition: 0s;
    }

    &:focus-visible {
      box-shadow: 0 0 0 10px $white-color, 0 0 0 12px $focus-color;
    }

    &:hover {
      .text-container {
        opacity: 0.8;
      }

      &::after {
        left: 125%;
        transition: 0.8s cubic-bezier(0.37, 0, 0.63, 1);
      }
    }
  }
}
</style>
