<template>
  <div class="modal-container">
    <div class="overlay"></div>
    <div class="modal content">
      <h1 class="title">{{ $t('rifModal.title') }}</h1>
      <div class="text">
        {{ $t('rifModal.description') }}
      </div>

      <div class="info-container">
        <div class="line">
          <span>
            {{ $t('website') }}
            <a :href="$t('rifModal.website')" target="_blank">
              <b>{{ $t('rifModal.website') }}</b>
            </a>
          </span>
        </div>
        <div class="line">
          <span> {{ $t('mail') }} <b>rif@mer.gouv.fr</b> </span>
        </div>
        <div class="line download-line">
          <span>
            <a href="/files/RIF_Hypothèques maritimes et saisies.xlsx">{{ $t('rifModal.downloadFile') }}</a>
          </span>
        </div>
      </div>
      <button @click="close">{{ $t('modal.close') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RifModal',
  props: {
    close: Function,
  },
};
</script>

<style scoped lang="scss">
.modal-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black-color, 0.4);
    opacity: 1;
  }

  .modal {
    position: relative;
    z-index: 10;
    height: fit-content;
    padding: 48px 32px;
    background: $white-color;
    box-shadow: 0 16px 24px -4px rgba(0, 0, 0, 0.2), 0 4px 8px -4px rgba($darkblue-color, 0.4);

    h1 {
      margin-bottom: 64px;
      color: $darkblue-color;
      font-family: $title-font;
      text-align: center;
      font-size: 48px;
      font-weight: bold;
    }

    .text {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;

      .name {
        font-weight: bold;
      }
    }

    .info-container {
      padding: 24px 32px;
      margin: 32px 0 0;
      background: rgba($focus-color, 0.15);
      border: solid 1px $focus-color;
      color: $darkblue-color;
      font-size: 14px;
      text-align: left;

      .line {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 4px;

        &.download-line {
          margin-top: 24px;
          text-decoration: underline;
        }

        a {
          font-weight: bold;
        }
      }

      svg {
        width: 16px;
        margin-right: 8px;
      }
    }

    button {
      height: 48px;
      width: 100%;
      max-width: 256px;
      margin: 48px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-shrink: 0;
      margin-left: auto;
      background: $darkblue-color;
      border: solid 2px transparent;
      font-size: 16px;
      color: $white-color;
      overflow: hidden;

      cursor: pointer;
      outline: solid 0 $focus-color;
      outline-offset: 1px;
      transition: 0.2s ease-out;

      &:focus-visible {
        outline: solid 2px $focus-color;
      }

      &:hover {
        background: lighten($darkblue-color, 8);
      }

      &:active {
        background: lighten($darkblue-color, 12);
        transform: scale(0.975);
      }
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .modal-container {
    .modal {
      padding: 64px 96px;
    }
  }
}
</style>
