<template>
  <div class="view legal">
    <div class="content">
      <h1>Informations fournies à l'Utilisateur concernant ses données à caractère personnel</h1>
      <h4>règlement UE 2016/679 du 27 avril 2016 (RGPD) et loi n°78-17 du 6 janvier 1978</h4>
      <h2>PRÉAMBULE</h2>
      <div class="container">
        <p><b>Le CNGTC</b> est une institution créée par la loi n°90-1259 du 31 décembre 1990 codifiée à l’article L.741-2
          du code de commerce, placée sous la tutelle du ministère de la justice. Il a pour mission la représentation de
          la profession auprès des pouvoirs publics et la défense des intérêts collectifs de la profession.</p>
        <p>Le CNGTC exerce également une mission de contrôle de la profession à travers notamment les inspections des
          greffes. Il a la charge de la formation initiale et continue des greffiers et de leurs salariés.</p>
        <p>Le CNGTC assure une mission d’information tant à l’égard de la profession que du public. Il est également
          chargé de la tenue du portail des sûretés mobilières et du fichier national des interdits de gérer.</p>
      </div>
      <h2>INFORMATIONS LÉGALES À DESTINATION DE L'UTILISATEUR</h2>
      <div class="container">
        <p>Les présentes ont notamment pour objet de donner à tout utilisateur des sites https://suretesmobilieres.fr et
          https://hypothequesmaritimes.cngtc.fr , les informations légales requises, lesquelles informations sont les
          suivantes (et ce séparément de toutes autres informations, conformément aux dispositions en vigueur) :</p>

        <h3>1. Identité et coordonnées du responsable du traitement de données à caractère personnel (ci-après le «
          traitement »)</h3>
        <p>
          L'identité et les coordonnées du responsable du traitement de données à caractère personnel sont les
          suivantes : Conseil National des Greffiers des Tribunaux de Commerce (CNGTC) Siège social : 29 rue Danielle
          Casanova - 75001 Paris. Adresse email fonctionnelle :
          <a href="mailto:contact@cngtc.fr">contact@cngtc.fr</a>.
        </p>

        <h3>2. Contact pour les demandes à la protection des données</h3>
        <p>
          Les coordonnées de la déléguée à la protection des données sont les suivantes : Mme Karla AMAN, Conseil
          National des Greffiers des Tribunaux de Commerce. Adresse postale : 29 rue Danielle Casanova - 75001 Paris.
          Les demandes des Utilisateurs concernant les données à caractère personnel pouvant être adressées à :
          <a href="mailto:rgpd@cngtc.fr">rgpd@cngtc.fr</a>.
        </p>

        <h3>3. Les données à caractère personnel objets d'une collecte</h3>
        <p>Les données à caractère personnel collectées sont les suivantes :
        <ul>
          <li>
            <p>
              les informations que fournit l'Utilisateur en remplissant les formulaires relatifs à la
              consultation du
              portail des suretés mobilières, en ce compris le portail des hypothèques maritimes :
            </p>
          </li>
          <li>
            <p>
              Ces informations peuvent concerner :
              <br />
              a) S'il s'agit d'une personne physique commerçante : ses nom, prénom et le numéro unique d'identification
              complété par la mention RCS suivie du nom de la ville où se trouve le greffe où elle est immatriculée ;
              <br />
              b) S'il s'agit d'une personne physique non commerçante ou d'un constituant à titre non professionnel : ses
              nom, prénom et son adresse pertinente si elle est connue et, s'il y a lieu, son numéro unique
              d'identification ;
              <br />
              c) S'il s'agit d'une personne morale unipersonnelle : sa forme, sa dénomination sociale, l'adresse de son
              siège ainsi que, le cas échéant, le numéro unique d'identification complété, s'il y a lieu, par la mention
              RCS suivie du nom de la ville où se trouve le greffe où elle est immatriculée ;
            </p>
          </li>
          <li>
            <p>
              elles comportent également les données recueillies grâce aux
              <a href="/charte-gestion-des-cookies">cookies</a> et technologies similaires utilisés sur le Site
              (notamment adresse IP) afin notamment de limiter les requêtes abusives.
            </p>
          </li>
        </ul>
        </p>

        <h3>4. Finalité du traitement</h3>
        <p>
          La finalité du traitement des données à caractère personnel est la diffusion des informations du portail
          des suretés mobilières, lequel permet une consultation nationale des registres des suretés mobilières tenus
          au plan local par les greffes des tribunaux de commerce et les greffes des juridictions statuant en matière
          commerciale.
        </p>

        <h3>5. Fondement juridique du traitement</h3>
        <p>
          La base juridique du traitement est l’obligation légale visée à l’article Art. R. 521-1 du code du commerce
          indiquant : « Il est également institué, sous la responsabilité du Conseil national des greffiers des
          tribunaux de commerce, un portail national accessible par le réseau internet permettant la consultation des
          informations inscrites dans les registres des sûretés mobilières tenus localement par chaque greffier »,
          issu du décret n°1887-2021 du 29 décembre 2021 publié après avis de la CNIL, le traitement étant administré
          conformément aux dispositions de la loi n°78-17 du 6 janvier 1978 et du règlement UE 2016/679 du 27 avril
          2016 (RGPD) et des autres dispositions légales en vigueur (ci-après la « Réglementation en vigueur »).
        </p>

        <h3>6. Destinataires des données à caractère personnel</h3>
        <p>
          Les destinataires des données à caractère personnel sont : les personnels des greffes des tribunaux de
          commerce et des greffes des juridictions statuant en matière commerciale en charge de la réalisation et/ou
          l’administration des demandes de consultation et/ou de téléchargement de Documents, le GIE Infogreffe en
          charge du traitement des demandes de délivrance d’extraits et, le cas échéant, les collaborateurs et les
          prestataires sous-traitants du CNGTC, du GIE Infogreffe et des greffes des tribunaux de commerce participant
          à la réalisation de ces missions et étant amené à intervenir à ce titre sur les traitements (uniquement
          lorsque cela est nécessaire), étant alors précisé qu’en pareille hypothèse cela est effectué dans le respect
          de la Réglementation en vigueur applicable aux sous-traitants.
        </p>

        <h3>7. Transfert des données à caractère personnel</h3>
        <p>
          L’Utilisateur est informé que le responsable du traitement peut, le cas échéant, effectuer un transfert de
          ses données à caractère personnel vers un pays tiers ou à une organisation internationale faisant l’objet
          d’une décision d’adéquation rendue par la commission européenne étant précisé que, en présence d’un
          transfert vers un pays ou une organisation internationale ne faisant pas l’objet d’une décision
          d’adéquation, ce transfert ne pourra être effectué qu’à la condition que soient mises en place les garanties
          appropriées et que les Utilisateurs concernés disposent de droits opposables et de voies de recours
          effectives, dans les conditions de la Réglementation en vigueur.
        </p>

        <h3>8. Durée de conservation des données à caractère personnel</h3>
        <p>
          La durée de conservation des données à caractère personnel par le portail est la suivante : les données
          personnelles étant nécessaires à la réalisation, administration et/ou livraison des prestations et/ou
          commandes réalisées via le Site, sont conservées durant 90 jours à compter de la collecte, durée au terme de
          laquelle les données à caractère personnel ne sont plus conservées.
        </p>

        <h3>9. Droits de l'Utilisateur pouvant être exercés auprès du responsable du traitement (tel qu'identifié
          ci-avant)</h3>
        <p>
          Les présentes ont également pour objet d'informer l'Utilisateur de l'existence de son droit de demander au
          responsable du traitement (tel qu'identifié ci-avant) :</p>
        <ul>
          <li>
            <p>
              l'accès à ses données à caractère personnel ainsi que toute information disponible quant à leur source ;
            </p>
          </li>
          <li>
            <p>
              la rectification ou l'effacement de celles-ci ;
            </p>
          </li>
          <li>
            <p>
              une limitation du traitement de ses données à caractère personnel
            </p>
          </li>
          <li>
            <p>
              la portabilité de ses données à caractère personnel dans les conditions de la Réglementation en vigueur
              laquelle dispose notamment que les personnes concernées par un traitement de données à caractère
              personnel ont le droit de recevoir les données à caractère personnel les concernant qu'elles ont
              fournies à un responsable du traitement, dans un format structuré, couramment utilisé et lisible par
              machine, et ont le droit de transmettre ces données à un autre responsable du traitement sans que le
              responsable du traitement auquel les données à caractère personnel ont été communiquées y fasse
              obstacle, lorsque: (i) le traitement est fondé sur le consentement en application de la Réglementation
              en Vigueur, ou sur un contrat en application de la Réglementation en vigueur et (ii) lorsque le
              traitement est effectué à l'aide de procédés automatisés, étant précisé que lorsque la personne
              concernée exerce son droit à la portabilité de ses données à caractère personnel, elle a le droit
              d'obtenir que ses données à caractère personnel soient transmises directement d'un responsable du
              traitement à un autre, lorsque cela est techniquement possible.
            </p>
          </li>
        </ul>
        <p>
          Le traitement de ces données personnelles s’inscrivant dans le cadre d’une obligation légale, l'Utilisateur
          ne dispose pas du droit d’opposition au traitement de ses données à caractère personnel. L'exercice par
          l'Utilisateur de ses droits tels qu'identifiés au présent (9) est réalisé au moyen d'une demande effectuée
          par ses soins et adressée à l'adresse suivante : <a href="mailto:rgpd@cngtc.fr">rgpd@cngtc.fr</a>.
        </p>

        <h3>10. Réclamation auprès de la CNIL</h3>
        <p>
          L'Utilisateur est informé du droit d'introduire une réclamation auprès de l'autorité de contrôle laquelle
          est la Commission Nationale de L'Informatique et des Libertés (CNIL) : 3 Place de Fontenoy - TSA 80715 -
          75334 Paris 07.
        </p>

        <h3>11. Prise de décision automatisée</h3>
        <p>
          Il est indiqué qu'il n'est pas procédé, au moyen des données à caractère personnel collectées, à une prise
          de décision automatisée au sens de la Réglementation en vigueur, à l’exclusion de processus techniques de
          limitation des requêtes abusives.
        </p>

        <h3>12. Réutilisation des données diffusées</h3>
        <p>
          Les informations des registres des sûretés mobilières sont mises à disposition du public sans frais. Il
          convient toutefois de distinguer la diffusion des informations, effectuée dans le cadre juridique détaillé
          précédemment, de la réutilisation de ces informations par des tiers.
          <br /><br />
          Le Conseil national ne peut être tenu responsable de la réutilisation de données publiques qui est effectuée
          par des tiers.
          <br /><br />
          Il est rappelé à l’Utilisateur qu’une personne physique peut s'opposer à la réutilisation des données la
          concernant sur un site web si elle dispose d'éléments tenant à sa situation particulière. Le site devra
          alors effacer les données sauf s'il démontre qu'il existe des motifs impérieux et légitimes prévalant sur
          les droits et libertés de la personne.
          <br /><br />
          Sans réponse au bout d'un mois ou en cas de refus d'effacer les données relatives à une personne physique,
          il est possible d'adresser une plainte à la CNIL.
          <br /><br />
          La personne physique peut également demander aux moteurs de recherche sur internet de ne plus associer le
          contenu des sites réutilisant les données du RNCS à ses nom et prénom, en exerçant son droit au
          déréférencement.
        </p>
      </div>
      <h2>MODIFICATIONS DES PRÉSENTES</h2>
      <div class="container">
        <p>Les présentes pourront faire l'objet d'une actualisation. A ce titre, l'Utilisateur est invité à prendre
          connaissance de la dernière version des présentes, accessible sur le Site.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Legal',
  components: {},
};
</script>

<style scoped lang="scss">
.view.legal {
  padding: 64px 0 96px;

  h1 {
    color: $blue-color;
    font-family: $title-font;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 32px;
  }

  h2 {
    color: $darkblue-color;
    font-family: $title-font;
    font-size: 24px;
    font-weight: bold;
    margin: 24px 0 16px;
  }

  h3 {
    margin: 16px 0 8px;
    font-size: 20px;
  }

  .address {
    padding-left: 12px;
    margin: 16px 0;
  }

  p {
    color: #777;
    margin: 2px 0 4px;
    line-height: 24px;

    a {
      text-decoration: underline;
      transition: 0.2s;

      &:hover {
        color: $focus-color;
      }
    }
  }

  ul {
    list-style: inside;

    li {
      display: flex;

      &::before {
        content: "";
        flex-shrink: 0;
        background: $darkblue-color;
        width: 4px;
        height: 4px;
        border-radius: 6px;
        margin: 12px 2px 0 8px;
      }
    }
  }
}
</style>
