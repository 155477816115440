import * as types from './mutations';

export const actions = {
  openModal({ commit }, { open }) {
    commit(types.SET_OPEN_MODAL, open);
  },
  async getHypothequesMaritimes({ commit }, { query }) {
    try {
      let formatedQuery = '?';
      for (const k of Object.keys(query)) {
        formatedQuery += `${k}=${query[k]}&`;
      }

      if (formatedQuery[formatedQuery.length - 1] === '&') formatedQuery = formatedQuery.slice(0, -1);

      formatedQuery = encodeURI(formatedQuery);

      const { hypothequesMaritimesGages, hypothequesMaritimesSaisies } = await this.$axios.$get(
        `search/${formatedQuery}`
      );

      const hypothequesMaritimesWithOutRIF = hypothequesMaritimesGages.filter(
        (hypotheque) => !hypotheque.donneesGenerales.inscriptionRif
      );

      const hypothequesMaritimesRIF = hypothequesMaritimesGages.filter(
        (hypotheque) => hypotheque.donneesGenerales.inscriptionRif
      );

      commit(types.SET_HYPOTHEQUES_MARITIMES, hypothequesMaritimesWithOutRIF);
      commit(types.SET_HYPOTHEQUES_RIF, hypothequesMaritimesRIF);
      commit(types.SET_SAISIES_NAVIRES, hypothequesMaritimesSaisies);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
