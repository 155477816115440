import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/Home.vue';
import Search from '../views/Search.vue';
import Export from '../views/Export.vue';
import Legal from '../views/Legal.vue';
import Cookies from '../views/Cookies.vue';
import RGPD from '../views/RGPD.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/recherche',
    name: 'Search',
    component: Search,
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/recherche/impression',
    name: 'Export',
    component: Export,
    meta: {
      nav: false,
      footer: false,
    },
  },
  {
    path: '/mentions-legales',
    name: 'Legal',
    component: Legal,
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/charte-gestion-des-cookies',
    name: 'Cookies',
    component: Cookies,
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/charte-rgpd',
    name: 'RGPD',
    component: RGPD,
    meta: {
      nav: true,
      footer: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
