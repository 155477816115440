<template>
  <div class="hypotheque-card">
    <transition name="modal">
      <CertifiedModal :close="closeModal" :hypothequeMaritime="hypothequeMaritime" v-if="certifiedModalOpen" />
    </transition>

    <div class="title-container">
      <div class="title">
        <div class="title-content">
          <h4>{{ $t('boatName') }}</h4>
          <h1>{{ hypothequeMaritime.infoHypotheque.nomNavire }}</h1>
        </div>
        <div class="label">{{ $t('hypotheque') }}</div>
      </div>

      <div class="greffe-address">
        <PinIcon />
        {{ $t('greffe') }} {{ hypothequeMaritime.infoHypotheque.greffe.nom }}
      </div>
    </div>

    <div class="id-container">
      <div class="port-container">
        <div class="port">
          {{ $t('portName') }} <b>{{ hypothequeMaritime.infoHypotheque.nomPortAttache }}</b>
        </div>
        <div class="boat-id">{{ $t('boatId') }} {{ hypothequeMaritime.infoHypotheque.numEnregistrementNavire }}</div>
        <div class="denomination-text">{{ $t('denominationText') }}</div>
      </div>
      <div class="registrationId">{{ $t('registerId') }} {{ hypothequeMaritime.infoHypotheque.numInscription }}</div>
    </div>

    <div class="vertu-container">
      <h2>{{ $t('under') }}</h2>
      <div class="value">{{ hypothequeMaritime.infoHypotheque.vertu }}</div>
    </div>

    <div
      class="unencumbered-fraction"
      v-if="hypothequeMaritime.debiteurs && this.hypothequeMaritime.debiteurs.partDetentionNavire !== ''"
    >
      <h2>{{ $t('unencumberedFraction') }}</h2>
      <div class="value">{{ formatPercentage(unencumberedFraction) }}</div>
    </div>

    <div class="container">
      <div class="creancier-container" v-if="hypothequeMaritime.creanciers">
        <h2>{{ $tc('creditors', hypothequeMaritime.creanciers.length) }}</h2>
        <template v-for="creancier in hypothequeMaritime.creanciers" :key="creancier.idCreancier">
          <div class="info" v-if="creancier.isMoraleCreancier">
            <div class="name">
              <span class="company">{{ creancier.denominationPersonneMoraleCreancier }}</span>
            </div>
            <div class="address">{{ creancier.adresseSiegeSocialCreancier }}</div>
            <div class="siren">
              {{ formatSiren(creancier.sirenCreancier) }} {{ $t('rcs') }} {{ creancier.villeRcs }}
            </div>
          </div>
          <div class="info" v-else>
            <div class="name">
              <span class="lastname">{{ creancier.nomCreancier }}</span>
              <span class="firstname">{{ creancier.prenomCreancier }}</span>
            </div>
            <div class="address">{{ creancier.domicileCreancier }}</div>
            <div class="address" v-if="domiciliationHypothèqueUe">{{ creancier.domiciliationHypothèqueUe }}</div>
          </div>
        </template>
      </div>
      <div class="debiteur-container" v-if="hypothequeMaritime.debiteurs">
        <h2>{{ $tc('owners', hypothequeMaritime.debiteurs.length) }}</h2>
        <template v-for="debiteur in hypothequeMaritime.debiteurs" :key="debiteur.idDebiteur">
          <div class="info" v-if="debiteur.isMoraleDebiteur">
            <div class="name">
              <span class="company">{{ debiteur.denominationPersonneMoraleDebiteur }}</span>
            </div>
            <div class="address">{{ debiteur.adresseSiegeSocialDebiteur }}</div>
            <div class="siren">{{ formatSiren(debiteur.sirenDebiteur) }} {{ $t('rcs') }} {{ debiteur.villeRcs }}</div>
            <div class="owners-fraction" v-if="unencumberedFraction !== 100">
              {{ $t('partDetentionNavire') }} <b>{{ formatPercentage(debiteur.partDetentionNavire) }}</b>
            </div>
          </div>
          <div class="info" v-else>
            <div class="name">
              <span class="lastname">{{ debiteur.nomDebiteur }}</span>
              <span class="firstname">{{ debiteur.prenomDebiteur }}</span>
            </div>
            <div class="address">{{ debiteur.domicileDebiteur }}</div>
            <div class="owners-fraction" v-if="unencumberedFraction !== 100">
              {{ $t('partDetentionNavire') }} <b>{{ formatPercentage(debiteur.partDetentionNavire) }}</b>
            </div>
          </div>
        </template>
      </div>
      <div class="info-container">
        <div class="info-item">
          <h2>{{ $t('amout') }}</h2>
          <div class="info">{{ formatPriceString(hypothequeMaritime.infoHypotheque.montantCreance) }}</div>
        </div>
        <div class="info-item">
          <h2>{{ $t('constitutionDat') }}</h2>
          <div class="info">{{ formatDate(hypothequeMaritime.donneesGenerales.dateInscription) }}</div>
        </div>
        <div class="info-item">
          <h2>{{ $t('rates') }}</h2>
          <div class="info">
            {{
              parseInt(hypothequeMaritime.infoHypotheque.txInteret)
                ? formatPercentage(hypothequeMaritime.infoHypotheque.txInteret)
                : $t('null')
            }}
          </div>
        </div>
        <div class="info-item">
          <h2>{{ $t('dueDate') }}</h2>
          <div class="info">
            {{ formatDate(hypothequeMaritime.infoHypotheque.dateExigibiliteCreance) || $t('null') }}
          </div>
        </div>
      </div>
    </div>

    <div class="complementary-container">
      <template v-for="observation in hypothequeMaritime.observations" :key="observation">
        <div class="observation" v-if="observation">
          {{ $t('observation') }}
          <span class="value">{{ observation }}</span>
        </div>
      </template>
      <div class="complimentary-info">
        {{ $t('specificProvisionText') }}
        <span class="value">
          {{ hypothequeMaritime.infoHypotheque.autresDispositionsContractuellesParticulieres ? $t('yes') : $t('no') }}
        </span>
      </div>
      <div class="complimentary-info">
        {{ $t('specificProvision244Text') }}
        <span class="value">
          {{ hypothequeMaritime.infoHypotheque.isDispositionParticuliereArticle244 ? $t('yes') : $t('no') }}
        </span>
      </div>

      <button class="document-button" @click="openModal">{{ $t('getDocument') }}</button>
    </div>
  </div>
</template>

<script>
import { formatSiren, formatPriceString, formatPercentage, formatDate } from '@/helpers/formatter.js';

import PinIcon from '@/assets/icons/Pin.svg';

import CertifiedModal from '@/components/CertifiedModal.vue';

export default {
  name: 'HypothequeCard',
  components: {
    PinIcon,
    CertifiedModal,
  },
  props: {
    hypothequeMaritime: Object,
  },
  data() {
    return {
      formatSiren,
      formatPriceString,
      formatPercentage,
      formatDate,
      certifiedModalOpen: false,
    };
  },
  computed: {
    unencumberedFraction() {
      return this.hypothequeMaritime.debiteurs.reduce(
        (acc, debiteur) => acc + (parseFloat(debiteur.partDetentionNavire) || 0),
        0
      );
    },
  },
  methods: {
    openModal() {
      this.$store.dispatch('openModal', { open: true });
      this.certifiedModalOpen = true;
      document.body.style.overflow = 'hidden';
    },
    closeModal() {
      this.$store.dispatch('openModal', { open: false });
      this.certifiedModalOpen = false;
      document.body.style.overflow = 'auto';
    },
  },
};
</script>

<style scoped lang="scss">
.hypotheque-card {
  position: relative;
  break-inside: avoid;
  page-break-before: always;

  width: 100%;
  color: $darkblue-color;
  background: $white-color;
  padding: 32px 48px;
  margin-bottom: 32px;

  overflow: hidden;

  h1 {
    margin-bottom: 16px;
    color: $darkblue-color;
    font-family: $title-font;
    font-size: 18px;
    font-weight: 700;
  }

  h2 {
    font-family: $title-font;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 16px;
  }

  .title-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title {
      display: flex;
      flex-wrap: wrap-reverse;
      align-items: center;
      text-transform: uppercase;

      .title-content {
        h4 {
          font-size: 12px;
          margin-bottom: 4px;
          margin-top: -16px;
          text-transform: none;
        }
      }

      .label {
        margin: 0 0 16px;
        padding: 8px 12px;
        border-radius: 4px;
        font-family: $title-font;
        font-size: 12px;
        font-weight: 700;

        color: $focus-color;
        background: rgba($focus-color, 0.15);
      }
    }
  }

  .id-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;

    .port-container {
      font-size: 14px;

      .denomination-text {
        font-style: italic;
        font-size: 12px;
        opacity: 0.6;
      }

      .port {
        margin-bottom: 4px;

        b {
          text-transform: capitalize;
        }
      }

      .boat-id {
        margin-bottom: 8px;
      }
    }

    .registrationId {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .greffe-address {
    display: flex;
    align-items: center;
    font-size: 14px;

    svg {
      height: 16px;
      margin-right: 8px;
    }
  }

  .info {
    font-size: 14px;
    margin-bottom: 12px;

    & > div:not(.name) {
      line-height: 18px;
    }

    .name {
      font-weight: 700;
      margin-bottom: 6px;

      span:not(:last-child) {
        margin-right: 4px;
      }
    }

    .address {
      margin-bottom: 8px;
    }

    .rcs {
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .vertu-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 16px;
    margin-bottom: 24px;

    h2 {
      margin-bottom: 0;
    }

    .value {
      color: $focus-color;
      margin-left: 8px;
    }
  }

  .unencumbered-fraction {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 16px;
    margin-bottom: 24px;

    h2 {
      margin-bottom: 0;
    }

    .value {
      color: $focus-color;
      margin-left: 8px;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    min-height: 128px;

    & > div {
      width: 100%;
      margin-bottom: 32px;
    }

    .info-container {
      display: flex;
      flex-wrap: wrap;
      margin-right: 0;
      width: 100%;

      .info-item {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  .complementary-container {
    .observation {
      margin-bottom: 16px;
      color: rgba($darkblue-color, 0.6);
      font-style: italic;
      font-size: 14px;

      grid-area: line-1;
    }

    .complimentary-info {
      font-size: 14px;
      margin-bottom: 24px;
      padding: 0 24px 0 0;

      .value {
        font-weight: 700;
        color: $focus-color;
      }
    }

    div {
      &:first-child {
        grid-area: line-1;
      }
      &:nth-child(2) {
        grid-area: line-2;
      }
      &:nth-child(3) {
        grid-area: line-3;
      }
    }
  }

  .document-button {
    grid-area: button;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    margin-left: auto;
    background: $darkblue-color;
    border: solid 2px transparent;
    font-size: 16px;
    color: $white-color;
    overflow: hidden;

    cursor: pointer;
    outline: solid 0 $focus-color;
    outline-offset: 1px;
    transition: 0.2s ease-out;

    svg {
      max-height: 24px;
      max-width: 24px;
      margin-right: 16px;
    }

    span {
      font-weight: 700;
    }

    &:focus-visible {
      outline: solid 2px $focus-color;
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: -32px;
      height: 100%;
      width: calc(100% + 64px);
      border-radius: 8px;
      background-color: rgba($white-color, 5%);

      transform: scaleX(0);
      transform-origin: 100% 100%;
      transition: transform 0.8s cubic-bezier(0.53, 0.21, 0, 1);
      will-change: transform;
    }

    &.loading {
      cursor: progress;

      &::after {
        transform-origin: 0 0;
        transform: scaleX(1);
        border-radius: 0;

        animation-name: grow;
        animation-duration: 1.6s;
        animation-iteration-count: infinite;
      }
    }

    &:not(.loading):hover {
      background: lighten($darkblue-color, 8);
    }

    &:not(.loading):active {
      background: lighten($darkblue-color, 12);
      transform: scale(0.975);
    }
  }
}

@media (min-width: $layout-breakpoint-medium) {
  .hypotheque-card {
    h1 {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 12px;
    }

    .title-container {
      flex-wrap: nowrap;

      .title {
        flex-wrap: nowrap;

        .label {
          margin: 0 16px;
        }
      }
    }

    .id-container {
      flex-wrap: nowrap;

      .registrationId {
        margin-bottom: 0;
      }
    }

    .container {
      flex-wrap: nowrap;
      margin-bottom: 48px;

      &.small {
        margin-bottom: 16px;

        & > div {
          width: 30%;
          margin-right: 16px;
          margin-bottom: 0;
        }
      }

      & > div {
        width: 30%;
        margin-right: 16px;
        margin-bottom: 0;
      }

      .info-container {
        width: 40%;

        .info-item {
          width: 50%;
          margin-bottom: 0;
        }
      }
    }

    .complementary-container {
      display: grid;
      grid-template: 'line-1 line-1 button' 'line-2 line-2 button' 'line-3 line-3 button';

      .complimentary-info {
        margin-bottom: 8px;
      }
    }

    .document-button {
      width: 320px;
    }
  }
}
</style>
