export function formatNumber(n, fixed = 0) {
  if (isNaN(n)) {
    n = parseFloat(n);
  }

  if (!isNaN(n) && n != null) {
    return Number(n)
      .toFixed(fixed)
      .replace(/./g, (c, i, a) => (i && c !== '\xa0' && (a.length - i) % 3 === 0 ? `\xa0${c}` : c))
      .replace('\xa0.', ',');
  }

  return 0;
}

export function formatPercentage(percentage) {
  return `${formatNumber(percentage, 2)}%`;
}

export function formatPriceString(price) {
  return formatNumber(price, 2) + '€';
}

export function formatPrice(price) {
  return price % 100 ? (price / 100).toFixed(2) : price / 100;
}

export function formatDate(date) {
  if (!date || date === '') return null;

  return new Date(date).toLocaleDateString('fr-FR');
}

export function formatPhone(phone) {
  return `${phone[0]}${phone[1]} ${phone[2]}${phone[3]} ${phone[4]}${phone[5]} ${phone[6]}${phone[7]} ${phone[8]}${phone[9]}`;
}

export function formatSiren(siren) {
  return siren.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
